<template>
	<div id="dash-solicitudes">
		<div class="container">
			<h2 class="text-center">Historico FRD</h2>
			<h3 class="text-center" v-if="exito == false">{{mensaje}}</h3>
			<div class="input-group">
				<div class="input-group mb-3 col-sm-3">
					<input
						v-model="val_search"
						type="search"
						id="search" 
						class="form-control" 
						placeholder="Solicitud"
					>
					<div class="input-group-append">
					<button type="button" class="btn btn-primary" @click.prevent="search()">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
							<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
						</svg>
					</button>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-4 offset-sm-8 text-right">
					<select v-model="options.num_items" name="" id="" class="form-control" @change="obtener_historico">
						<option value="5">Mostrando 5 registros</option>
						<option value="10">Mostrando 10 registros</option>
						<option value="20">Mostrando 20 registros</option>
						<option value="50">Mostrando 50 registros</option>
						<option value="100">Mostrando 100 registros</option>
					</select>
				</div>
			</div>

			<div :key="comite.index" class="solicitudes mb-4" v-for="comite of comites">
				<div class="solicitud text-center">
					<div class="row row-mobile d-block d-sm-none">
						<div class="col">
							<div>
								<span class="label-datos-generales">No.Comité</span> <br />
								<span class="datos-generales-mobile">{{ comite.numero }}</span>
							</div>
						</div>
					</div>
					<div class="row row-mobile">
						<div class="col col-sm-2 d-none d-sm-block">
							<div>
								<div class="label-datos-generales">No Comité</div>
								<div class="datos-generales">{{ comite.numero }}</div>
							</div>
						</div>
						<div class="col">
							<div>
								<div class="label-datos-generales">Fecha inicio</div>
								<div class="datos-generales">{{ comite.fecha_inicio }}</div>
							</div>
						</div>
						<div class="col">
							<div>
								<div class="label-datos-generales">Fecha fin</div>
								<div class="datos-generales">{{ comite.fecha_fin }}</div>
							</div>
						</div>
						<div class="col">
							<div>
								<div class="label-datos-generales">Número de solicitudes</div>
								<div class="datos-generales">{{ comite.solicitudes.length }}</div>
							</div>
						</div>
						<button
							:data-target="'#solicitud-collapse-'+ comite.id"
							aria-controls="collapseSolicitud"
							aria-expanded="false"
							class="btn col-md-2 d-none d-sm-block text-right"
							data-toggle="collapse"
							type="button"
						>
							<img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/arrow-down-blue.svg" width="40px" />
						</button>
					</div>
					<button
						:data-target="'#solicitud-collapse-'+ comite.id"
						aria-controls="collapseSolicitud"
						aria-expanded="false"
						class="btn col-md-2 d-block d-sm-none"
						data-toggle="collapse"
						type="button"
					>
						<img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/arrow-down-blue.svg" width="40px" />
					</button>
					<!-- <hr /> -->
				</div>
				<div :id="'solicitud-collapse-' + comite.id" class="collapse">
					<h3 class="text-center">Solicitudes</h3>
					<div v-for="solicitud in comite.solicitudes" class="card card-body" :style="voto(solicitud)" :key="solicitud.id">
						<div class="row row-mobile">
							<div class="col d-block d-sm-none">
								<div class="d-flex justify-content-between title-mobile">
									<span class="label-datos-generales">id Solicitud</span>
									<span class="datos-generales">{{ solicitud.id }}</span>
								</div>
							</div>
						</div>
						<div class="row row-mobile">
							<div class="col col-sm-2 d-none d-sm-block">
								<div>
									<div class="label-datos-generales">ID Solicitud</div>
									<div class="datos-generales">{{ solicitud.id }}</div>
								</div>
							</div>
							<div class="col">
								<div>
									<div class="label-datos-generales">Monto</div>
									<div class="datos-generales">{{ solicitud.autorizado_comite ? '$'+solicitud.autorizado_comite : 'No aprobado' }}</div>
								</div>
							</div>
							<div class="col">
								<div>
									<div class="label-datos-generales">Tasa interes</div>
									<div class="datos-generales">{{ solicitud.tasa_interes ? solicitud.tasa_interes : 'No aprobado' }}</div>
								</div>
							</div>
							<div class="col">
								<div>
									<div class="label-datos-generales">Aforo</div>
									<div class="datos-generales">{{ solicitud.aforo ? solicitud.aforo : 'No aprobado' }}</div>
								</div>
							</div>
							<div class="col">
								<div>
									<div class="label-datos-generales">Plazo</div>
									<div class="datos-generales">{{ solicitud.plazo ? solicitud.plazo : 'No aprobado' }}</div>
								</div>
							</div>
							<div class="col">
								<div>
									<div class="label-datos-generales">Fecha que se cedió</div>
									<div class="datos-generales">{{ solicitud.create_cesion_credito }}</div>
								</div>
							</div>
							<div class="col solicitud">
								<div>
									<div class="label-datos-generales">Votaciones</div>
									<button
												:data-target="'#solicitud-collapse-'+ solicitud.id"
												aria-controls="collapseSolicitud"
												aria-expanded="false"
												class="btn col-md-2 d-none d-sm-block text-right"
												data-toggle="collapse"
												type="button"
											>
												<img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/arrow-down.svg" width="40px" />
											</button>
								</div>
							</div>

							<div class="acciones-general col">
								<div class="row">
									<div class="col detalle">
											
									</div>
								</div>
							</div>

						</div>

						<div :id="'solicitud-collapse-' + solicitud.id" class="collapse">
							<div class="table-responsive">
								<table class="table">
									<caption v-if="solicitud.votaciones_cesion_creditos.length > 0 ">Observaciones</caption>
									<caption v-else-if="solicitud.votaciones_cesion_creditos.length == 0 ">Sin Observaciones</caption>
									<thead>
										<tr>
											<th scope="col">Nombre</th>
											<th scope="col">Status</th>
											<th scope="col">Observación</th>
											<th scope="col">Fecha Observación</th>
										</tr>
									</thead>
									<tbody>
										<tr :style="statusCredito(observacion.status)" v-for="observacion in solicitud.votaciones_cesion_creditos" :key="observacion.id">
											<td class="info">{{observacion.usuario_cesion_credito.nombre}}</td>
											<td v-if="observacion.status == 0">Rechazado</td>
											<td v-if="observacion.status == 1">Aceptado</td>
											<td>{{observacion.observacion}}</td>
											<td>{{observacion.created_at}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-12">
					<nav>
						<ul class="pagination justify-content-center align-items-center">
							<li v-if="options.page > 1" class="page-item" @click="cargar_pagina(options.page-1)"><img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/before.svg" width="40px" /></li>
							<li v-else class="page-item disabled"><img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/before-disabled.svg" width="40px" /></li>

							<li v-for="index in total_paginas" :class="'page-item'+(index == options.page ? ' active' : '')" @click="cargar_pagina(index)"><span class="page-link">{{ index }}</span></li>

							<li v-if="options.page < total_paginas.length" class="page-item"><img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/after.svg" width="40px" @click="cargar_pagina(options.page+1)"/></li>
							<li v-else class="page-item disabled"><img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/after-disabled.svg" width="40px"/></li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import comite from '@/apps/comite/api/comite'

	export default {
		data: () => ({
			exito: true,
			mensaje: '',
			comites: []
			,no_items: 0
			,options: {
				page: 1
				,num_items: 10
			},
			val_search: null,
		})
		,mounted: function() {
			this.obtener_historico();
		}
		,methods: {
			obtener_historico: async function() {
				try{
					var res = await comite.obtener_historico_cesion_creditos(this.options)

					this.$log.info('res', res);
					this.comites = res.data.data;
					this.no_items = res.data.total;
					this.options.page = res.data.current_page;
					if(res.data.data.length == 0){
						this.$notify({
							group: 'alert'
							,type: 'warning'
							,title: 'Solicitud'
							,text: 'No existen resultados.'
							,duration: 4000
						})
					}
				}catch(err) {
					this.$log.info('err', err)
					this.$helper.showAxiosError(err,'Error');
				}
			}
			,formatPrice(value) {
		        let val = (value/1).toFixed(0).replace(',', '.')
		        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		    }
			,voto: function(solicitud) {
				return 'background-color: #0781cd';
				if (solicitud.estado_id == 4)
					return 'background-color: #24b361';
				else if(solicitud.estado_id == 5){
					return 'background-color: #dc3545';
				}else {
					return 'background-color: #0781cd';
				}
			}
			,statusCredito: function(status) {

				if (status == 0)
					return 'background-color: #f2dede;'
				else if(status == 1){
					return 'background-color: #d9edf7;'
				}

				return 'background-color: #fff;'
			}
			,cargar_pagina: function(pag) {
				this.$log.info('pag', pag);
				this.options.page = pag;
				this.obtener_historico();
			},

			async search(){
				if(this.val_search !=null && this.val_search != ''){
					try{
						var res = await comite.obtener_historico_solicitud_cesion_creditos(this.val_search)
						this.$log.info('res', res);
						this.comites = res.data.data;
						this.no_items = res.data.total;
						this.options.page = res.data.current_page;
						if(res.data.data.length == 0){
							this.$notify({
								group: 'alert'
								,type: 'warning'
								,title: 'Solicitud'
								,text: 'No existen resultados.'
								,duration: 4000
							})
						}
					}catch(err) {
						this.$log.info('err', err)
						this.$helper.showAxiosError(err,'Error');
					}
					this.val_search = null
					
				}else{
					this.obtener_historico();
				}
			},

			
		}
		,computed: {
			total_paginas: function() {
				let total = Math.ceil(this.no_items / this.options.num_items);

				let paginas = [];

				let numPag = 3;
				let inicio = 1;

				if (this.options.page > numPag)
					inicio = this.options.page - numPag;

				let final = total;

				if ((this.options.page+numPag) < total)
					final = this.options.page+numPag;

				for(let i=inicio; i<=final; i++) {
					paginas.push(i);
				}

				return paginas;
			}
		}
	}
</script>

<style lang="scss" scoped>
table{
	caption{
		color: $white;
	}
}
thead {
  background-color: #f0fafd!important;
	th{
		color: $text-primary;
	}
}
tbody {
	td{
		color: $text-primary;
	}
}

li.page-item {
	border-radius: 5px !important;
	overflow: hidden;

	span {
		&:active {
			background-color: #0681cd !important;
    	border-color: #0681cd !important;
		}
	}
}

.card {
	min-height: auto !important;
	color:#fff;
	display: flex;
	justify-content: center;
}

#dash-solicitudes {
	background-color: $white;
	// min-height: 100vh;
	margin-bottom: 2rem;
	.container{
		margin-bottom: 2rem;
	}
}
h2 {
	color: $text-primary;
	padding: 1rem 0;
}
.solicitudes {
	background: #e5e5e5;
	margin-top: 2%;
	padding: 1rem;

	.collapse {
		border: 1px solid #e5e5e5;
    // border-radius: 0px 0px 5px 5px;
		margin-top: 2rem;
		
		.title-mobile {
			border-bottom: 1px dashed #fff;
			padding-bottom: .5rem;

			span {
				color: #fff !important;
			}
		}
	}

	.solicitud {

		button {
			&:focus {
				outline: none !important;
				box-shadow: none;
			}
			&[aria-expanded="true"] {
				.arrow-down {
					transition: all 0.8s;
					transform: rotate(-180deg);
				}
			}
			&[aria-expanded="false"] {
				.arrow-down {
					transition: all 0.8s;
				}
			}
		}
	}

	@include mq-max(mobile-big) {
		.solicitud {
			text-align: center;
			.row-mobile {
				border-bottom: 1px dashed;
				margin-bottom: 2%;
				padding-bottom: 2%;
			}
			button {
				padding: 0%;
			}
		}
	}
}
.datos-generales {
	font-family: $nunito-regular;
}
.datos-generales-mobile {
	font-family: $nunito-semibold;
	font-size: 16px;
}
.label-datos-generales {
	font-family: $nunito-bold;
	font-size: 18px;
}
.generales-data {
	display: flex;
	justify-content: space-between;
	.text-right {
		font-weight: 700;
		padding-right: 0;
		color: $text-primary;
	}
	.heanding-mobile {
		color: $text-primary;
		font-size: 16px;
	}
}
.acciones-general {
	// margin-top: 5%;
	padding-left: 0;

	@include mq-max(tablet){
		border-top: 1px dashed;
    margin-top: 1rem;
    padding-top: 1rem;
	}
	ul {
		display: flex;
		padding-left: 0;
		justify-content: flex-start;
		margin-bottom: 0;

		li {
			list-style: none;
			margin-right: 8%;
			.icon-action {
				width: 40px;
			}
		}
		@include mq-min(tablet) {
			li {
				padding-right: 4%;
				margin-right: 2%;
			}
		}
	}

	.detalle {
		text-align: right;
		font-family: $nunito-regular;
		color: $color-blue;
		padding-right: 0%;
		align-items: center;
		display: flex;
		justify-content: flex-end;
		a{
			min-width: 150px;
			color: #fff;
			text-decoration: underline;
			// &:hover{
			// 	color: $color-green;
			// }
		}
		img {
			&:hover {
				color: $color-green;
			}
		}
	}
}
</style>